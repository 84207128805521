<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Ajouter un contact</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Ajouter un contact" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <form-category title="Rechercher un professionnel" subtitle="Veuillez sélectionner un professionnel à ajouter"
                       class="mt-4"
        />
        <v-card-text class="px-0">
          <professional-autocomplete v-model="guest" dense />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn x-small class="mx-1" @click="$emit('close')">Annuler</v-btn>
          <v-btn color="secondary" x-small class="mx-1" :disabled="!guest || submitting" :loading="submitting" @click="addContact">
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import ProfessionalAutocomplete from '@/modules/professionals/components/ProfessionalAutocomplete'
  import * as Contacts from '@/modules/network/api'
  export default {
    name: 'InviteContact',
    components: {ModalsSidebar,FormCategory,ProfessionalAutocomplete},
    data() {
      return {
        guest: null,
        submitting: false
      }
    },
    methods: {
      async addContact() {
        this.submitting = true
        let [err] = await Contacts.sendRequest(this.guest.uid)
        this.$emit('close')
        if (err) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'info',
            title: 'Demande de contact déja envoyée',
            subtitle: 'Une demande de contact a déjà été envoyée à cet utilisateur.'
          })
          return
        }
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Demande envoyée',
          subtitle: 'La demande a été envoyée avec succès.'
        })
        this.submitting = false
      }
    }
  }
</script>
